import ContentfulImage from '@/components/contentful-image';
import { SectionIC } from '@/lib/interface-types';
import uuid from 'react-uuid';
import styles from './index.module.css';

interface Props {
  section: SectionIC;
}

interface Image {
  url: string[];
  altText: string[];
}

const Awards = ({ section }: Props) => {
  const {
    borderless,
    title,
    imagesCollection: { items }
  } = section;
  const formatTitle = title.split(' ');

  return (
    <div
      className={`awards mt-20 mb-28 ${borderless ? 'lg:mx-0' : 'lg:mx-20'}`}
    >
      <div className={`${!borderless ? styles.awardBox : ''}`}>
        {borderless ? (
          <h2>
            {formatTitle[0]}
            <span className="ml-2 text-brand-green lg:ml-0 ">
              {formatTitle[1]}
            </span>
          </h2>
        ) : (
          <div className={`${styles.cutEdge}`}> </div>
        )}
        <div
          className={`items-center justify-between min-h-[300px] flex flex-col lg:flex-row 
    gap-16 lg:gap-16 xl:gap-20 2xl:gap-40 ${
      borderless ? 'mt-10 md:px-0 lg:px-0' : 'md:px-10 lg:px-20'
    }`}
        >
          {!borderless && (
            <div className="flex justify-center items-center mt-10 lg:mt-0 lg:flex-col">
              <h2>{formatTitle[0]}</h2>
              <h2 className="ml-2 text-brand-green lg:ml-0">
                {formatTitle[1]}
              </h2>
            </div>
          )}
          <div
            className={`images min-h-[350px] px-10 pb-10 grid grid-cols-2 gap-8 w-full md:grid-cols-3 
            ${
              borderless ? 'xl:grid-cols-4' : ''
            } lg:px-0 lg:pb-0 lg:gap-8 lg:min-h-[200px] xl:gap-12 md:gap-16`}
          >
            {items?.map((img: Image) => (
              <div
                key={uuid()}
                className={`flex items-center ${
                  borderless ? 'xl:min-h-[120px] md:min-h-[80px]' : ''
                }`}
              >
                <div className="relative w-full h-full">
                  <ContentfulImage
                    className="object-contain"
                    src={img.url}
                    alt={img.altText}
                    fill
                    sizes="99.99vw"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
